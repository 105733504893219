import { om2FormModal } from 'omni-campaign-ui';
import { api } from '../helpers/api.js';

// When we use tree view in dropdown, every option needs a unique id
let count = 0;
const uniqueId = () => {
  count += 1;
  return `id:${count}`;
};

// Adjust the tree structure to what our dropdown can render
const massageModuleData = (data, parent) => {
  if (data.steps) {
    return {
      name: data.name,
      selectable: false,
      id: uniqueId(),
      children: data.steps.map(x => massageModuleData(x)).filter(x => x),
    };
  }
  if (data.step_modules) {
    return {
      name: data.name,
      selectable: false,
      id: uniqueId(),
      children: data.step_modules
        .map(x => massageModuleData(x, data.uuid_step))
        .filter(x => x),
    };
  }
  if (data.module && data.module.module_type === 'form') {
    return {
      name: data.module.name,
      id: `${parent}:${data.module.uuid_module}`,
    };
  }
  return null;
};

// Find all the field references within a section of the ui schema
const getRefs = uiSchema => {
  if (!uiSchema) return [];
  if (Array.isArray(uiSchema)) {
    return uiSchema.map(getRefs).flat(1);
  }
  if (uiSchema.type === 'ref') {
    return [uiSchema.id];
  }
  return getRefs(uiSchema.content);
};

// Find all arrays within the ui schema
const getArrays = uiSchema => {
  if (!uiSchema) return [];
  if (Array.isArray(uiSchema)) {
    return uiSchema.map(getArrays).flat(1);
  }
  if (uiSchema.type === 'array') {
    return [
      {
        name: `${uiSchema.id} (array)`,
        id: uiSchema.id,
        refs: getRefs(uiSchema),
        isArray: true,
      },
    ];
  }
  return getArrays(uiSchema.content);
};

export const openPptxHelper = async frameworkUUID => {
  const ft = await api.getFrameworkTemplateModules(frameworkUUID);
  const modules = massageModuleData(ft).children;

  const schema = {
    module: {
      title: 'Module (form)',
      type: 'string',
      enum: modules,
      treeView: true,
      hasObjectValues: true,
      hasSearch: true,
      required: true,
      formModal: { fullWidth: true },
    },
    field: {
      title: 'Field',
      tooltip:
        'The input field within the selected form module. To get the object/persona name use "objectName"',
      type: 'string',
      enum: [],
      hasObjectValues: true,
      hasSearch: true,
      required: true,
      disabled: true,
      formModal: { fullWidth: true },
    },
    nestedField: {
      title: 'Nested Field',
      tooltip: 'The nested field within the selected array',
      type: 'string',
      enum: [],
      disabled: true,
      formModal: {
        fullWidth: true,
        hideIf: { id: ['field', 'isArray'], op: '!==', value: true },
      },
    },
    arrayPosition: {
      title: 'Array Position',
      type: 'number',
      min: 0,
      multipleOf: 1,
      tooltip: 'The position within the array (0 is the first)',
      formModal: {
        hideIf: { id: ['field', 'isArray'], op: '!==', value: true },
      },
    },
    objectPosition: {
      title: 'Object Position',
      type: 'number',
      min: 0,
      multipleOf: 1,
      tooltip:
        'If the step has enrichment enter which object you want (0 is the first), if there is no enrichment leave blank',
    },
    result: {
      type: 'text',
      formModal: { fullWidth: true },
      class: 'is-family-monospace',
    },
  };

  const formModalElem = om2FormModal({
    title: 'PPTX Helper',
    submitButtonText: 'Done',
    onSubmit: () => {},
    schema,
  });

  formModalElem.addEventListener('change', async e => {
    const { changes } = formModalElem.form;
    const [stepId, moduleId] = changes?.module?.id?.split(':') ?? [];
    const key = e?.detail?.key;

    // Module was set to a value
    if (key === 'module' && moduleId) {
      schema.field.disabled = true;
      formModalElem.requestUpdate();

      const module = await api.getModule(moduleId);
      const fields = Object.entries(module.data_structure.schema).map(
        ([id, field]) => ({
          id,
          name: `"${field.title ?? ''}" [${id}] (${field.type})`,
          sharedField: field.sharedField,
        })
      );
      getArrays(module.data_structure.uiSchema).forEach(array =>
        fields.push(array)
      );
      fields.push({ id: 'objectName', name: 'The enrichment object name' });
      schema.field.enum = fields;
      delete changes.field;
      schema.field.disabled = false;
    }

    // Field was set to a value
    if (key === 'field' && changes.field) {
      schema.nestedField.disabled = true;
      delete changes.nestedField;
      delete changes.arrayPosition;
      const { isArray } = changes.field;
      if (isArray) {
        schema.nestedField.enum = changes.field.refs;
      }
      schema.nestedField.disabled = false;
    }

    // Generate the template string
    let result = '';
    if (changes.field && changes.field.sharedField) {
      result = 'shared';
      result += `['/form/${changes.field.id.replaceAll("'", "\\'")}'].value`;
      result = `{{ ${result} }}`;
    } else if (stepId && moduleId && changes.field) {
      // Syntax structure
      // - step[step_id].module[module_id].field
      // - step[step_id].module[module_id].object[object_order].field
      result = `step['${stepId}'].module['${moduleId}']`;
      if (
        (changes.objectPosition !== '' &&
          changes.objectPosition !== undefined) ||
        changes.field.id === 'objectName'
      ) {
        result += `.object[${changes.objectPosition || 0}]`;
      }

      if (changes.field.id.match(/^[a-zA-Z_][a-zA-Z0-9_]*$/)) {
        result += `.${changes.field.id}`;
      } else {
        result += `['${changes.field.id.replaceAll("'", "\\'")}']`;
      }

      if (changes.field.isArray) {
        result += `[${changes.arrayPosition || 0}]`;
      }

      if (changes.nestedField) {
        if (changes.nestedField.match(/^[a-zA-Z_][a-zA-Z0-9_]*$/)) {
          result += `.${changes.nestedField}`;
        } else {
          result += `['${changes.nestedField.replaceAll("'", "\\'")}']`;
        }
      }
      result = `{{ ${result} }}`;
    }

    // Set template string and update
    schema.result.text = result;
    formModalElem.requestUpdate();
  });
};
