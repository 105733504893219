import WorkflowStepContainer from './workflow-step-container.js';
import { ContextConsumer, workflowContext } from './helpers/context.js';
import { api } from './helpers/api.js';

export default class WorkflowStage extends WorkflowStepContainer {
  constructor() {
    super();
    this.tabs = ['Steps'];
    this.stepLabel = 'Step';
    this.workflowConsumer = new ContextConsumer(this, {
      context: workflowContext,
      subscribe: true,
      callback: value => {
        this.stages = value?.steps?.map(stage => ({
          name: stage.name,
          id: stage.uuid_step,
        }));
      },
    });
  }

  static get properties() {
    return {
      stageId: this.routeParamProperty({ name: 'stageId' }),
      workflowId: this.routeParamProperty({ name: 'workflowId' }),
    };
  }

  get breadcrumb() {
    return {
      label: this.data?.name,
      link: `workflow/${this.workflowId}/${this.stageId}`,
    };
  }

  loadData = () => api.getStep(this.stageId);
  loadDataProperty = 'stageId';

  manageStep(step) {
    this.navigateTo(
      `workflow/${this.workflowId}/${this.stageId}/${step.uuid_step}`
    );
  }
}

customElements.define('workflow-stage', WorkflowStage);
