import {
  OmniElement,
  OmniStyleElement,
  OmniIconElement,
  html,
  css,
} from 'omni-ui';

OmniStyleElement.register();
OmniIconElement.register();

/**
 * Class for Step Card Element
 * Replicates step-card design on campaign overview page for purposes of previewing styles
 * @extends OmniElement
 */
export default class StepCard extends OmniElement {
  /**
   * Step Card styles
   */
  static get styles() {
    return [
      super.styles,
      css`
        :host {
          --omni-card-height: 11.429rem;
          --omni-card-min-width: 21.429rem;
          --omni-card-max-width: 25%;
        }
        omni-card::part(card-img),
        omni-card::part(card-icon) {
          display: none;
        }
        omni-card::part(card-footer) {
          border-top: 0.14rem solid var(--color-core-medium-two);
          padding: 0.29rem 0.43rem;
        }
        omni-card::part(card-footer) {
          justify-content: space-evenly;
          align-content: center;
          align-items: center;
        }
        omni-icon[icon-id='omni:informative:complete'] {
          vertical-align: bottom;
        }
        omni-icon[icon-id='omni:informative:complete'],
        omni-icon[icon-id='omni:informative:complete'] + span {
          --color-icon-lines: rgb(var(--rgb-primary));
          color: rgb(var(--rgb-primary));
        }
      `,
    ];
  }

  /**
   * @returns {html} Rendered HTML for the component
   */
  render() {
    return html`<omni-style>
      <omni-card
        href="javascript: void(0);"
        openmsg="Open"
        @open=${e => {
          e.preventDefault();
        }}
      >
        <h1 slot="card-title" class="title is-5 mb-2">Step Name</h1>
        <h2 slot="card-type" class="mt-1">Step 1</h2>
        <p slot="card-desc">This is the step description text.</p>
        <div slot="card-status">
          <div class="ml-2">
            <omni-icon
              class="is-size-1 is-inline-block mr-2"
              icon-id="omni:informative:complete"
              aria-hidden="true"
            ></omni-icon>
            <span class="is-size-6">Step Completed</span>
          </div>
        </div>
      </omni-card>
    </omni-style>`;
  }
}

customElements.define('step-card', StepCard);
