import {
  OmniElement,
  OmniStyleElement,
  OmniIconElement,
  html,
  css,
  nothing,
} from 'omni-ui';
import { Om2Dropdown } from 'omni-campaign-ui';
import { api } from './helpers/api.js';
import './helpers/image-modal.js';
import { isSuperAdmin } from './helpers/util.js';

OmniStyleElement.register();
OmniIconElement.register();
Om2Dropdown.register();

/**
 * @summary Class for ModuleModal
 * @description Component for module modals that allows for dynamic rendering of modal inputs based on selections from other inputs.
 * @extends OmniElement
 */
export default class ModuleModal extends OmniElement {
  /**
   * ModuleModal Lit Properties
   * @property {String} modalTitle - add/edit depending on trigger that opens modal
   * @property {Boolean} isModuleLocked - boolean that determines whether moduleType dropdown is enabled/disabled
   * @property {Object} savedData - object containing current form data;
   * @property {Function} closeModal - callback function to close the modal by removing this component from the dom
   *
   * @property {Array} moduleTemplates - array of objects containing all default templates for modules
   * @property {String} moduleName - name of the module; defaulted to '' if no previous {@link savedData} is available
   * @property {String} moduleDescription - description of the module; defaulted to '' if no previous {@link savedData} is available
   * @property {String} moduleDataStructure - template JSON structure of the module; defaulted to {} if no previous {@link savedData} is available
   * @property {String} moduleType - type of the module that links it to its template; defaulted to '' if no previous {@link savedData} is available
   * @property {String} moduleImageURL - imageURL of the modules template screenshot; defaulted to '' if no previous {@link savedData} is available
   * @property {String} moduleTemplateId - UUID of the module template; defaulted to '' if no previous {@link savedData} is available
   * @property {Object} uuidModule - id of the module being edited
   */
  static get properties() {
    return {
      modalTitle: { type: String },
      isModuleLocked: { type: Boolean },
      savedData: { type: Object },
      closeModal: { type: Function },

      // Internally set properties
      moduleTemplates: { attribute: false },
      moduleName: { attribute: false },
      moduleDescription: { attribute: false },
      moduleDataStructure: { attribute: false },
      moduleType: { attribute: false },
      moduleImageURL: { attribute: false },
      moduleTemplateId: { attribute: false },
      uuidModule: { attribute: false },
    };
  }

  /**
   * Initialize
   * {@link #moduleTemplates},
   * {@link #moduleDescription},
   * {@link #moduleName},
   * {@link #moduleDataStructure},
   * {@link #moduleImageURL},
   * {@link #moduleType} properties
   */
  constructor() {
    super();
    this.moduleTemplates = [];
    this.moduleDescription = '';
    this.moduleName = '';
    this.moduleDataStructure = {};
    this.moduleImageURL = '';
    this.moduleType = '';
    this.init();
  }

  static styles = css`
    .omni .modal-card {
      overflow: auto;
    }
  `;

  async init() {
    // Get module templates once from api and store in static variable
    if (!this.constructor._moduleTemplates) {
      this.constructor._moduleTemplates = await api.listModuleTemplates();
    }
    this.moduleTemplates = this.constructor._moduleTemplates;
  }

  updated(changedProperties) {
    /**
     * If we have saved data update internally used properties
     */
    if (this.savedData && changedProperties.has('savedData')) {
      const {
        savedData: {
          description,
          module_type: moduleType,
          name,
          data_structure: dataStructure,
          uuid_module: uuidModule,
          uuid_module_template: moduleTemplateId,
        } = {},
      } = this;
      this.moduleDescription = description;
      this.moduleType = moduleType;
      this.moduleName = name;
      this.moduleDataStructure = dataStructure;
      this.uuidModule = uuidModule;
      this.moduleTemplateId = moduleTemplateId;
    }
  }

  async submitModule(e) {
    e.preventDefault();
    const moduleData = {
      name: this.moduleName,
      description: this.moduleDescription,
      module_type: this.moduleType,
      data_structure: this.moduleDataStructure,
      uuid_module_template: this.moduleTemplateId,
    };
    if (['Add', 'Clone'].includes(this.modalTitle)) {
      const { uuid } = await api.createModule(moduleData);
      this.dispatchNewEvent('module-created', {
        detail: { module: { ...moduleData, uuid_module: uuid } },
      });
    }
    if (this.modalTitle === 'Edit') {
      await api.updateModule(this.uuidModule, moduleData);
      this.dispatchNewEvent('module-updated', {
        detail: { module: { ...moduleData, uuid_module: this.uuidModule } },
      });
    }
    this.closeModal();
  }

  _renderTemplateBlock = () =>
    html` <div class="modal-card">
      <header class="modal-card-head pb-0">
        <h2 class="title is-size-2">${this.modalTitle}</h2>
      </header>

      <form @submit=${this.submitModule}>
        <section class="modal-card-body">
          <div class="columns">
            <div class="column">
              <div class="mb-2">
                <span class="subtitle">Name</span>
              </div>
              <input
                type="text"
                class="input"
                .value=${this.moduleName}
                @change=${e => {
                  this.moduleName = e.target.value;
                }}
                placeholder="Name"
                required
              />
            </div>
            <div class="column">
              <div class="mb-2">
                <span class="subtitle">Description</span>
              </div>
              <input
                type="text"
                class="input"
                .value=${this.moduleDescription}
                @change=${e => {
                  this.moduleDescription = e.target.value;
                }}
                placeholder="Description"
                required
              />
            </div>
          </div>
          <div class="columns">
            <div class="column is-half">
              <div class="mb-2">
                <span class="subtitle">Module Type</span>
              </div>
              <om2-dropdown
                .placeholder=${this.moduleTemplates?.length ? '' : 'Loading...'}
                @change=${({ target: { value: moduleType } }) => {
                  const {
                    template: moduleDataStructure,
                    image_url: imageURL,
                    uuid_module_template: moduleTemplateId,
                  } = this.moduleTemplates.find(
                    type => type.key === moduleType
                  ) || {};
                  this.moduleType = moduleType;
                  this.moduleImageURL = imageURL;
                  this.moduleTemplateId = moduleTemplateId;

                  // If editing and the selected module type matches the saved module,
                  // use data structure from saved module otherwise from template.
                  this.moduleDataStructure =
                    moduleType && this?.savedData?.module_type === moduleType
                      ? this.savedData.data_structure
                      : moduleDataStructure ?? {};
                }}
                .options=${this.moduleTemplates.map(ele => ({
                  label: ele.name,
                  value: ele.key,
                }))}
                .value=${this.moduleTemplates?.length ? this.moduleType : null}
                required
                ?disabled=${this.isModuleLocked}
              ></om2-dropdown>
            </div>

            <div class="column is-half">
              <div class="mb-2">
                <span class="subtitle">Template Image</span>
                <omni-tooltip
                  class="ml-1 ${this.isModuleLocked ? 'is-hidden' : ''}"
                >
                  <omni-icon
                    slot="invoker"
                    class="is-size-3 is-clickable"
                    icon-id="omni:informative:info"
                  ></omni-icon>
                  <div slot="content">
                    Select a Module Type from dropdown for image
                  </div>
                </omni-tooltip>
              </div>
              ${this.moduleImageURL?.length > 0
                ? html`<image-modal
                    imageURL="${this.moduleImageURL}"
                  ></image-modal>`
                : 'n/a'}
            </div>
          </div>
          ${isSuperAdmin()
            ? html`
                <div
                  class="${this.modalTitle === 'Clone' ? 'is-hidden' : ''} mb-2"
                >
                  <span class="subtitle">Data Structure</span>
                </div>
                <textarea
                  class="${this.modalTitle === 'Clone'
                    ? 'is-hidden'
                    : ''} textarea is-family-code is-size-5"
                  .value=${JSON.stringify(this.moduleDataStructure, null, 2) ??
                  ''}
                  @input=${e => {
                    e.stopPropagation();
                    const errorElem = e.target.nextElementSibling;
                    let parsed;

                    try {
                      parsed = JSON.parse(e.target.value);
                      errorElem.innerText = '';
                    } catch (e2) {
                      if (e.target.value === '') {
                        errorElem.innerText = '';
                      } else {
                        errorElem.innerText = e2;
                      }
                    }
                    if (errorElem.innerText === '') {
                      this.moduleDataStructure = parsed;
                    }
                  }}
                >
                </textarea>
              `
            : nothing}

          <span class="has-text-danger is-size-5"></span>
        </section>
        <footer class="modal-card-foot is-flex is-justify-content-flex-end">
          <button type="button" class="button" @click=${this.closeModal}>
            Cancel
          </button>
          <button type="submit" class="button is-primary">Submit</button>
        </footer>
      </form>
    </div>`;

  render() {
    return html`<omni-style>
      <div class="modal is-active">
        <div
          class="modal-background"
          @click=${this.closeModal}
          @keyup=${e => e.key === 'Enter' && e.target.click()}
        ></div>
        ${this._renderTemplateBlock()}
      </div>
    </omni-style>`;
  }
}

customElements.define('module-modal', ModuleModal);
