import { OmniElement, OmniStyleElement, OmniIconElement, html } from 'omni-ui';
import { om2FormModal, Om2Table } from 'omni-campaign-ui';
import { api } from './helpers/api.js';
import { showLoader } from './helpers/util.js';

OmniStyleElement.register();
OmniIconElement.register();
Om2Table.register();

export default class ContentTypeList extends OmniElement {
  constructor() {
    super();
    this.data = [];
  }

  firstUpdated() {
    this.reload();
  }

  static get properties() {
    return {
      data: { type: Array },
    };
  }

  get breadcrumb() {
    return {
      label: 'Content Types',
      link: 'content-types',
      toolbarActions: { Add: () => this.create() },
    };
  }

  async reload() {
    this.data = await showLoader(this, api.listContentTypes());
  }

  create() {
    om2FormModal({
      title: 'Create Content Type',
      data: {},
      onSubmit: api.createContentType,
      onSuccess: () => this.reload(),
      schema: {
        name: {
          title: 'Name',
          type: 'string',
        },
        description: {
          title: 'Description',
          type: 'string',
        },
      },
    });
  }

  edit(type) {
    om2FormModal({
      title: 'Edit Content Type',
      data: type,
      onSubmit: data => api.updateContentType(type.uuid_content_type, data),
      onSuccess: () => this.reload(),
      schema: {
        name: {
          title: 'Name',
          type: 'string',
        },
        description: {
          title: 'Description',
          type: 'string',
        },
      },
    });
  }

  render() {
    return html`
      <omni-style>
        <om2-table
          autosort
          autotooltip
          shadowed
          .search=${['name']}
          .columns=${[
            {
              label: 'name',
              key: 'name',
              isSortable: true,
              isMain: true,
            },
            {
              label: 'description',
              key: 'description',
              isSortable: true,
            },
            {
              label: 'actions',
              passthrough: true,
              template: type => html`
                <td>
                  <button
                    @click="${() => this.edit(type)}"
                    class="button is-text"
                    title="Edit"
                  >
                    <omni-icon
                      class="is-size-2"
                      icon-id="omni:interactive:edit"
                    ></omni-icon>
                  </button>
                </td>
              `,
            },
          ]}
          .data="${this.data}"
        >
          <span slot="header-start">Content Types</span>
        </om2-table>
      </omni-style>
    `;
  }
}

customElements.define('content-type-list', ContentTypeList);
