import {
  OmniElement,
  OmniStyleElement,
  OmniIconElement,
  html,
  css,
  nothing,
} from 'omni-ui';

OmniStyleElement.register();
OmniIconElement.register();

export default class ImageModal extends OmniElement {
  constructor() {
    super();
    this.imageURL = '';
    this.isActive = false;
  }

  static get properties() {
    /**
     * @property {Boolean} isActive boolean that determines whether imageModal is active or not
     * @property {String} imageURL imageURL of the modules template screenshot; defaulted to '' if no previous {@link savedData} is available
     */
    return {
      imageURL: { type: String },
      isActive: { type: Boolean },
    };
  }

  /**
   * Manage Module styles
   */
  static get styles() {
    return [
      super.styles,
      css`
        .omni .modal {
          z-index: 41;
        }
      `,
    ];
  }

  _toggleModal() {
    this.isActive = !this.isActive;
  }

  render() {
    return html` <omni-style>
      <img
        class="is-clickable"
        alt="Thumbnail"
        tabindex="0"
        @click=${() => this._toggleModal()}
        @keyup=${e => e.key === 'Enter' && e.target.click()}
        src=${this.imageURL}
      />

      ${this.isActive
        ? html` <div class="modal is-active">
            <div
              class="modal-background"
              tabindex="0"
              @click=${this._toggleModal}
              @keyup=${e => e.key === 'Enter' && e.target.click()}
            ></div>
            <div class="modal-content">
              <p class="image">
                <img src=${this.imageURL} alt="" />
              </p>
            </div>
            <button
              class="modal-close is-large"
              @click=${this._toggleModal}
              aria-label="close"
            ></button>
          </div>`
        : nothing}
    </omni-style>`;
  }
}

customElements.define('image-modal', ImageModal);
