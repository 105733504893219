import {
  OmniElement,
  OmniStyleElement,
  OmniIconElement,
  html,
  css,
} from 'omni-ui';
import { om2FormModal } from 'omni-campaign-ui';
import { api } from './helpers/api.js';
import { showLoader } from './helpers/util.js';
import './dynamic-form-builder.js';

OmniStyleElement.register();
OmniIconElement.register();

export default class ModuleTemplates extends OmniElement {
  /**
   * Module Templates Lit Properties
   * @property {Array} data - Data returned from API that populates table
   * @property {Object} visibleTemplate - Template for Module used to display modal code block
   */
  static get properties() {
    return {
      data: { type: Array },
      visibleTemplate: { type: Object },
    };
  }

  /**
   * Module Templates styles
   */
  static get styles() {
    return [
      super.styles,
      css`
        ::part(module-type-img) {
          border-radius: 1rem;
        }

        ::part(template-json) {
          opacity: 0.3;
          max-width: 20rem;
          max-height: 15rem;
          overflow: hidden;
          z-index: 1;
        }
        ::part(open-template-button) {
          z-index: 2;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
        ::part(template-wrapper) {
          position: relative;
        }
        ::part(template-json-overlay) {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: rgb(242, 245, 250);
          border-radius: 1rem;
        }
        .json-modal {
          background: rgb(242, 245, 250) !important;
          border-radius: 1rem;
        }
      `,
    ];
  }

  /**
   * Initialize {@link #data} properties
   */
  constructor() {
    super();
    this.data = [];
  }

  get breadcrumb() {
    return {
      label: 'Module Templates',
      link: 'module-templates',
      toolbarActions: { Add: () => this.create() },
    };
  }

  firstUpdated() {
    this.reload();
  }

  async reload() {
    this.data = await showLoader(this, api.listModuleTemplates());
  }

  create() {
    om2FormModal({
      title: 'Add Module Template',
      data: {},
      onSubmit: data => {
        const copy = { ...data };
        copy.assist_fields =
          copy.assist_fields?.split(/\s+/)?.filter(field => field.length) ?? [];
        return api.addModuleTemplates(copy);
      },
      onSuccess: () => this.reload(),
      schema: {
        name: {
          title: 'Name',
          type: 'string',
          required: true,
        },
        description: {
          title: 'Description',
          type: 'string',
        },
        key: {
          title: 'Key',
          type: 'string',
          pattern: '^[a-z][a-z_]+[a-z]$',
          htmlTitle:
            'Lowercase (a-z) only, must start and end with a letter, underscores (_) instead of spaces',
          required: true,
        },
        image_url: {
          tooltip: 'Upload screenshot of generic module',
          title: 'Image URL',
          type: 'image',
        },
        template: {
          title: 'Template',
          type: 'object',
          default: {},
          formModal: { fullWidth: true },
        },
        assist_fields: {
          title: 'Omni Assist Fields',
          tooltip:
            'The list of fields in this module that can be omni-assist-enabled (one per line or white-space delimited).',
          type: 'string',
          multiline: true,
          formModal: { fullWidth: true },
        },
      },
    });
  }

  edit(moduleTemplate) {
    om2FormModal({
      title: 'Edit Module Template',
      data: {
        ...moduleTemplate,
        assist_fields: moduleTemplate.assist_fields.join('\n'),
      },
      onSubmit: data => {
        const copy = { ...data };
        copy.assist_fields = copy.assist_fields
          .split(/\s+/)
          .filter(field => field.length);
        return api.updateModuleTemplates(
          moduleTemplate.uuid_module_template,
          copy
        );
      },
      onSuccess: () => this.reload(),
      schema: {
        name: {
          title: 'Name',
          type: 'string',
          required: true,
        },
        description: {
          title: 'Description',
          type: 'string',
        },
        key: {
          title: 'Key',
          type: 'string',
          disabled: true,
        },
        image_url: {
          tooltip: 'Upload screenshot of generic module',
          title: 'Image URL',
          type: 'image',
        },
        template: {
          title: 'Template',
          type: 'object',
          default: {},
          formModal: { fullWidth: true },
        },
        assist_fields: {
          title: 'Omni Assist Fields',
          tooltip:
            'The list of fields in this module that can be omni-assist-enabled (one per line).',
          type: 'string',
          multiline: true,
          formModal: { fullWidth: true },
        },
      },
    });
  }

  _selectCodeBlock = () => {
    const templateJSON = this.shadowRoot.querySelector('.json-modal');
    window.getSelection().selectAllChildren(templateJSON);
  };

  _renderTemplateBlock = template =>
    html`<div class="modal-card">
      <header class="modal-card-head pb-0">
        <h2 class="title is-size-2">Template</h2>
      </header>
      <section class="modal-card-body">
        <pre class="json-modal"><code>${JSON.stringify(
          template,
          undefined,
          2
        )}</code></pre>
      </section>
      <footer class="modal-card-foot is-flex is-justify-content-flex-end">
        <button class="button" @click="${this._selectCodeBlock}">Select</button>
        <button
          class="button"
          @click=${() => {
            this.visibleTemplate = null;
          }}
        >
          Close
        </button>
      </footer>
    </div>`;

  render() {
    return html` <omni-style>
      <div class="modal ${this.visibleTemplate ? 'is-active' : ''}">
        <div
          class="modal-background"
          @click=${() => {
            this.visibleTemplate = null;
          }}
          @keyup=${e => e.key === 'Enter' && e.target.click()}
        ></div>
        ${this._renderTemplateBlock(this.visibleTemplate)}
      </div>

      <om2-table
        autosort
        autotooltip
        shadowed
        .search=${['name']}
        .columns=${[
          {
            label: 'name',
            key: 'name',
            isSortable: true,
            isMain: true,
          },
          {
            label: 'id',
            key: 'uuid_module_template',
            isSortable: true,
          },
          {
            label: 'description',
            key: 'description',
            isSortable: true,
          },
          {
            label: 'key',
            key: 'key',
            isSortable: true,
          },
          {
            label: 'image URL',
            key: 'image_url',
            template: image => {
              if (!image) {
                return html` <td class="has-text-centered">n/a</td> `;
              }
              return html`
                <td
                  part="table-body-cell table-body-image"
                  class="has-text-centered is-clamped"
                >
                  <image-modal imageURL="${image}"></image-modal>
                </td>
              `;
            },
            isSortable: false,
          },
          {
            label: 'template',
            key: 'template',
            template: template => {
              if (JSON.stringify(template) === '{}')
                return html` <td class="has-text-centered">n/a</td> `;
              return html`
                <td class="is-clamped">
                  <div part="template-wrapper">
                    <button
                      @click=${() => {
                        this.visibleTemplate = template;
                      }}
                      part="open-template-button"
                      class="button is-primary"
                    >
                      View Template
                    </button>
                    <div part="template-json-overlay"></div>
                    <pre part="template-json"><code>${JSON.stringify(
                      template,
                      undefined,
                      2
                    )}</code></pre>
                  </div>
                </td>
              `;
            },
            isSortable: false,
          },
          {
            label: 'actions',
            passthrough: true,
            template: moduleTemplate => html`
              <td>
                <button
                  @click="${() => this.edit(moduleTemplate)}"
                  class="button is-text"
                  title="Edit"
                >
                  <omni-icon
                    class="is-size-2"
                    icon-id="omni:interactive:edit"
                  ></omni-icon>
                </button>
              </td>
            `,
          },
        ]}
        .data="${this.data}"
      >
        <span slot="header-start">Module Templates</span>
      </om2-table>
    </omni-style>`;
  }
}

customElements.define('module-templates', ModuleTemplates);
