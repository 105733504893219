import { html } from 'omni-ui';
import { api } from './helpers/api.js';
import { workflowContext, ContextProvider } from './helpers/context.js';
import WorkflowStepContainer from './workflow-step-container.js';
import './preview-campaign-object.js';

export default class WorkflowView extends WorkflowStepContainer {
  // Viewing a framework template is very similar to viewing a step, so we
  // extend that class and override the necessary differences.

  constructor() {
    super();
    // Need to use a different property name when adding a step
    this.stepParentIdKeyOverride = 'uuid_framework_template';

    this.tabs = ['Stages', 'Settings', 'Statuses'];
    this.stepLabel = 'Stage';
    this.toggleToolbarAction(false);

    this.workflowProvider = new ContextProvider(this, {
      context: workflowContext,
    });

    // If a theme gets added/removed we need to refresh
    this.addEventListener('theme-updated', this.reload);
  }

  static get properties() {
    return {
      preview: { type: Boolean },
      ...super.properties,
    };
  }

  async updated(changedProperties) {
    super.updated(changedProperties);
    if (changedProperties.has('data') && this.data) {
      this.workflowProvider.setValue(this.data);
    }
  }

  get breadcrumb() {
    return {
      label: this.data?.name,
      link: `workflow/${this.workflowId}`,
      toolbarActions: {
        [this.preview ? 'Builder' : 'Preview']: this.toggleToolbarAction,
      },
    };
  }

  toggleToolbarAction = preview => {
    this.preview = preview ?? !this.preview;
  };

  manageStep(stage) {
    this.navigateTo(`workflow/${this.workflowId}/${stage.uuid_step}`);
  }

  loadData = () => api.getFrameworkTemplate(this.workflowId);
  loadDataProperty = 'workflowId';

  render() {
    return this.preview
      ? html` <preview-campaign-object
          .object=${this.data}
          objectType="framework-template"
        >
        </preview-campaign-object>`
      : super.render();
  }
}

customElements.define('workflow-view', WorkflowView);
