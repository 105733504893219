import {
  OmniElement,
  OmniStyleElement,
  OmniIconElement,
  html,
  css,
  unsafeCSS,
  live,
} from 'omni-ui';

import Pickr from '@simonwep/pickr';

OmniStyleElement.register();
OmniIconElement.register();

/**
 * Variable to change size of color picker element
 */
const pickerWidth = '14.286rem';

/**
 * Class for Color Picker Element
 * @extends OmniElement
 */
export default class ColorPicker extends OmniElement {
  /**
   * Color Picker Lit Properties
   * @property {Object} color - RGB value of color
   * @property {Object} colorVarName - Name of omni-ui color variable
   * @property {Boolean} pickerReady - Indicates if the pickr object is initialized
   */
  static get properties() {
    return {
      color: { type: String },
      colorVarName: { type: String },
      pickerReady: { type: Boolean, state: true },
    };
  }

  /**
   * Color Picker styles
   */
  static get styles() {
    return [
      super.styles,
      css`
        .colorful {
          width: 2.143rem;
          height: 2.5rem;
          background-color: var(--pcr-color);
          border-radius: 6px 0 0 6px;
          border: 1px solid transparent;
          border-right: none;
        }
        .color-picker-container {
          display: flex;
          flex-direction: row-reverse;
          align-items: center;
          width: ${unsafeCSS(pickerWidth)};
        }
        .omni .input {
          flex-grow: 1;
          border-radius: 0 6px 6px 0;
        }
        .omni input:hover ~ .colorful {
          border-color: var(--color-gray-b5);
        }
        .omni input:focus ~ .colorful,
        .omni input:active ~ .colorful,
        .omni input:focus-visible ~ .colorful {
          border-color: rgb(var(--rgb-primary));
        }
        .omni input,
        .omni input:hover,
        .omni input:active,
        .omni input:focus-visible {
          border-left: none !important;
        }
        div.pcr-app[data-theme='classic'] {
          width: 14.5rem;
        }
      `,
    ];
  }

  /**
   * Updates color picker color value after {@link #color} is updated
   */
  updated(props) {
    // We have to wait unti the picker object is intialized before calling setColor()
    // to avoid a bug where the color resets to the color passed to "new Pickr(...)"
    if (
      (props.has('color') || props.has('pickerReady')) &&
      this.pickerReady &&
      this.color
    ) {
      this.picker.setColor(`rgb(${this.color})`);
    }
  }

  /**
   * Create and append color picker elements to document body on initial page load
   * Uses 3rd party Pickr library https://github.com/Simonwep/pickr
   * Directs color picker elements to call {@link #_dispatchChange} on change/hide
   * NOTE: Color picker and input are separate entities
   */
  firstUpdated() {
    // selects elements with
    const pickerEl = this.shadowRoot.querySelector('.colorful');

    this.picker = new Pickr({
      // Selector or element which will be replaced with the actual color-picker.
      // Can be a HTMLElement.
      el: pickerEl,

      // Where the pickr-app should be added as child.
      container: 'body',
      theme: 'classic',
      useAsButton: true,

      // Defines change/save behavior:
      // False applies color to button and preview (save) in sync with each change
      comparison: false,

      // Default color
      default: `rgb(${this.color})`,

      // Default color representation of the input/output textbox.
      // Valid options are `HEX`, `RGBA`, `HSVA`, `HSLA` and `CMYK`.
      defaultRepresentation: 'RGBA',

      // Defines the position of the color-picker.
      position: 'bottom-start',

      components: {
        hue: true, // Display hue slider
      },
    });

    this.picker
      // element is appended to body so DOM node has to be manually accessed to style the width of the color picker
      .on('show', (_, { _root: { app } }) => {
        // eslint-disable-next-line no-param-reassign
        app.style.width = pickerWidth;
      })
      // converts valid color formats (hex, hsl, color names) to RGB array which is needed for omni-theme colors
      .on('change', color => {
        const colorArray = color.toRGBA().map(ele => ele.toFixed());
        this._dispatchChange(
          `${colorArray[0]}, ${colorArray[1]}, ${colorArray[2]}`
        );
      })
      .on('hide', () => this._dispatchChange(this.color))
      .on('init', () => {
        this.pickerReady = true;
      });
  }

  /**
   * @description Handles changes triggered by color picker manipulation and by input
   * @fires dispatchNewEvent#color-change
   * @param {string} color - RGB color value in string format
   */
  async _dispatchChange(color) {
    const nextRGBValue = `rgb(${color})`;
    const colorInput = document.querySelector('input');

    this.dispatchNewEvent('color-change', {
      detail: {
        color,
        key: this.colorVarName,
      },
    });
    // check to update the value of the input when the user changes the color via the picker
    if (nextRGBValue !== colorInput.value) {
      colorInput.value = nextRGBValue;
      this.requestUpdate();
    }
  }

  /**
   * @returns {html} Rendered HTML for the component
   * Uses lit live directive to set input.value if it differs from the live DOM value rather than the last-rendered value
   */
  render() {
    return html` <omni-style>
      <div class="color-picker-container">
        <input
          type="text"
          @change="${e => this.picker.setColor(e.target.value)}"
          class="input"
          .value=${live(`rgb(${this.color})`)}
          spellcheck="false"
        />
        <div class="colorful"></div>
      </div>
    </omni-style>`;
  }
}

customElements.define('color-picker', ColorPicker);
