import {
  OmniElement,
  OmniStyleElement,
  OmniIconElement,
  html,
  nothing,
} from 'omni-ui';
import {
  Om2Table,
  Om2IconMenu,
  om2FormModal,
  om2alert,
  om2confirm,
} from 'omni-campaign-ui';
import { api } from './helpers/api.js';

Om2IconMenu.register();
OmniStyleElement.register();
OmniIconElement.register();
Om2Table.register();

/**
 * Class for Workflow Statuses Element
 * @extends OmniElement
 */
export default class WorkflowStatuses extends OmniElement {
  /**
   * Workflow Statuses Lit Properties
   * @property {Array} statuses - Array of custom statuses defined on this framework template
   * @property {String} workflowId - UUID of the framework template
   * @property {Boolean} disabled - Indicates if add/edit/delete functionality should be disabled
   */
  static get properties() {
    return {
      statuses: { type: Array },
      workflowId: { type: String },
      disabled: { type: Boolean },
    };
  }

  static iconOptions = [
    { id: 'omni:informative:notStarted', name: 'Not Started' },
    { id: 'omni:informative:inProgress', name: 'In Progress' },
    { id: 'omni:informative:inReview', name: 'In Review' },
    { id: 'omni:informative:skipped', name: 'Skipped' },
    { id: 'omni:informative:complete', name: 'Complete' },
  ];

  static defaultStatuses = [
    {
      icon: 'omni:informative:notStarted',
      name: 'Not Started',
      completion: 0,
      display_order: 1,
    },
    {
      icon: 'omni:informative:inProgress',
      name: 'In Progress',
      completion: 0,
      display_order: 2,
    },
    {
      icon: 'omni:informative:inReview',
      name: 'In Review',
      completion: 0,
      display_order: 3,
    },
    {
      icon: 'omni:informative:skipped',
      name: 'Skipped',
      completion: 100,
      display_order: 4,
    },
    {
      icon: 'omni:informative:complete',
      name: 'Complete',
      completion: 100,
      display_order: 5,
    },
  ];

  /**
   * @description Triggers page reload
   * @fires dispatchNewEvent#reload
   */
  reload = () => {
    this.dispatchNewEvent('reload');
  };

  #addOrEditStatus(status) {
    om2FormModal({
      title: `${status ? 'Edit' : 'Add'} Custom Status`,
      data: status,
      onSubmit: async form => {
        form.completion = form.completion ? 100 : 0;
        let id = status?.uuid_framework_template_status;
        if (this.#isDefault) {
          // This is a little weird, currently the default statuses are used if there
          // are none saved in the db. But the UI still shows them and allows editing
          // etc. So to add/edit/delete we first need to create the defaults.
          const response = await api.batchAddCustomStatuses(
            this.workflowId,
            this.constructor.defaultStatuses
          );
          if (status) {
            // Find correct id of newly created status if we're editing
            id = response.uuid[status.display_order - 1];
          }
        }
        if (status) {
          await api.editCustomStatus(this.workflowId, id, form);
        } else {
          await api.addCustomStatus(this.workflowId, form);
        }
      },
      onSuccess: () => {
        om2alert(
          'Your custom status has been added to your framework template',
          {
            type: 'success',
            toast: true,
            closeTimeout: 4000,
          }
        );
        this.reload();
      },
      schema: {
        name: {
          title: 'Name',
          type: 'string',
          required: true,
        },
        display_order: {
          title: 'Display Order',
          type: 'number',
          min: 1,
          multipleOf: 1,
          default: (this.statuses.length ?? 0) + 1,
          required: true,
        },
        completion: {
          title: 'Include in Progress',
          type: 'boolean',
          tooltip:
            'When checked, the step status is 100% complete. If unchecked the step status is 0% complete. This is used when calculating the overall completion in the campaign progress bar.',
        },
        icon: {
          title: 'Icon',
          type: 'string',
          enum: this.constructor.iconOptions,
          required: true,
        },
      },
    });
  }

  async #deleteStatus(status) {
    om2confirm(`Delete status "${status.name}"?`, {
      callback: async () => {
        let id = status.uuid_framework_template_status;
        if (this.#isDefault) {
          // This is a little weird, currently the default statuses are used if there
          // are none saved in the db. But the UI still shows them and allows editing
          // etc. So to add/edit/delete we first need to create the defaults.
          const response = await api.batchAddCustomStatuses(
            this.workflowId,
            this.constructor.defaultStatuses
          );
          id = response.uuid[status.display_order - 1];
        }
        const lastStatus = this.statuses.length === 1;
        await api.deleteCustomStatus(this.workflowId, id);
        this.reload();
        if (lastStatus) {
          om2alert(
            'Last custom status deleted. Reverting to default statuses.',
            {
              type: 'info',
              toast: true,
              closeTimeout: 4000,
            }
          );
        }
      },
      type: 'warning',
    });
  }

  get #isDefault() {
    return !this.statuses?.length;
  }

  /**
   * @returns {html} Rendered HTML for the component
   */
  render() {
    return html`
      <omni-style>
        <om2-table
          autosort="display_order,asc"
          autotooltip
          shadowed
          .search=${['name']}
          .columns=${[
            {
              label: 'Name',
              key: 'name',
              isMain: true,
              isSortable: true,
              template: name => html`
                <td
                  class="is-clamped ${this.#isDefault ? 'has-text-shark' : ''}"
                >
                  <span>${name}</span>
                </td>
              `,
            },
            {
              label: 'Order',
              key: 'display_order',
              isSortable: true,
            },
            {
              label: 'Include in Progress',
              key: 'completion',
              isSortable: true,
              template: included => html`
                <td class="is-clamped">
                  ${included
                    ? html`<omni-icon
                        class="is-size-1"
                        icon-id="omni:informative:check"
                      ></omni-icon>`
                    : nothing}
                </td>
              `,
            },
            {
              label: 'Icon',
              key: 'icon',
              isSortable: true,
              template: icon => html`
                <td class="is-clamped">
                  <omni-icon
                    class="is-size-2"
                    icon-id="${icon}"
                    title="${icon}"
                  ></omni-icon>
                </td>
              `,
            },
            {
              label: 'actions',
              passthrough: true,
              template: status => {
                return html`
                  <td>
                    <span
                      title=${this.disabled
                        ? 'Edit action unavailable because this framework template is live. You will need to clone this framework template in order to change statuses'
                        : 'Edit'}
                    >
                      <button
                        slot="invoker"
                        @click=${() => this.#addOrEditStatus(status)}
                        class="button is-text"
                        ?disabled=${this.disabled}
                      >
                        <omni-icon
                          class="is-size-2"
                          icon-id="omni:interactive:edit"
                        ></omni-icon>
                      </button>
                    </span>

                    <span
                      title=${this.disabled
                        ? 'Delete action unavailable because this framework template is live. You will need to clone this framework template in order to change statuses'
                        : 'Delete'}
                    >
                      <button
                        slot="invoker"
                        @click=${() => this.#deleteStatus(status)}
                        class="button is-text ml-4"
                        ?disabled=${this.disabled}
                      >
                        <omni-icon
                          class="is-size-2"
                          icon-id="omni:interactive:delete"
                        ></omni-icon>
                      </button>
                    </span>
                  </td>
                `;
              },
            },
          ]}
          .data="${this.#isDefault
            ? this.constructor.defaultStatuses
            : this.statuses}"
        >
          <slot name="header-start" slot="header-start"></slot>

          <span
            slot="header-center-end"
            class="pr-6 has-text-weight-bold has-text-shark"
          >
            ${this.#isDefault ? 'Default Statuses' : nothing}
          </span>

          <span
            slot="header-end"
            class="is-inline-block"
            title=${this.disabled
              ? 'Add action unavailable because this framework template is live. You will need to clone this framework template in order to change statuses'
              : 'Add'}
          >
            <button
              @click=${() => this.#addOrEditStatus()}
              class="button is-small is-outlined"
              ?disabled=${this.disabled}
            >
              Add
            </button>
          </span>
        </om2-table>
      </omni-style>
    `;
  }
}

customElements.define('workflow-statuses', WorkflowStatuses);
