/**
 * Data used by @see{@link campaign-donut-chart}
 */
export const strategicDataSample = {
  brand: {
    order: '3',
    percentage: '20',
    placeholder: "What is the brand's specific behavior?",
    subtitle: 'Brand',
    text: '',
  },
  category: {
    order: '4',
    percentage: '20',
    placeholder: 'What role does the brand play in the category?',
    subtitle: 'Category',
    text: '',
  },
  comms: {
    order: '5',
    percentage: '20',
    placeholder: "What is the brand's unique communications approach?",
    subtitle: 'Comms',
    text: '',
  },
  consumer: {
    order: '2',
    percentage: '20',
    placeholder: 'Does the brand have a unique communication approach?',
    subtitle: 'Consumer',
    text: '',
  },
  culture: {
    order: '1',
    percentage: '20',
    placeholder: 'What mark in culture is the brand trying to make?',
    subtitle: 'Culture',
    text: '',
  },
};

/**
 * Sample campaign data mostly used for @see{@link campaign-banner}
 */
export const campaignDataSample = {
  name: 'Test Campaign',
  start_date: '2021-07-12',
  end_date: '2021-07-12',
  description: 'This is the test objective',
  image_url:
    'https://devimages.annalect.com/images/eyJrZXkiOiI1N2IyYTQwNWIwZWQzMzVkYzZhMDhkMmQ4NTIwMTcyZTUxOTE4Yjg3NWU0ZmY2OWUxNjQ4NTY1OTY4MjQ3In0=#testimage.jpg',
  brand_image_url:
    'https://devimages.annalect.com/images/eyJrZXkiOiI3Y2I4NzBlZjFmMzNkMTIyNjMzYTk5NGFlYTc2MGU1ZjFkNTZiMmViM2U2ODk5NWMxNjQ4MjI5OTY0MDc4In0=#MicrosoftTeams-image.png',
  budget: 9999999,
  currency: 'USD',
  framework_template: {
    active: true,
    assigned_clients: [
      {
        uuid_client: '449203c6-7ed3-11e8-8b6b-0a35455287ac',
      },
    ],
    data_points: {},
    description: 'Lorum ipsum',
    diagram: '',
    module_data_map: {},
    name: 'Developers Testing',
    ppt_url: '',
    settings: [
      {
        pptx_name: '',
        theme_object: {
          '--color-accent-four': '#bf000d',
          '--color-accent-one': '#9f8158',
          '--color-accent-three': '#93000a',
          '--color-accent-two': '#d98d97',
          '--rgb-primary': '159, 129, 88',
          '--rgb-secondary': '217, 189, 151',
          '--rgb-tertiary': '147, 0, 10',
          name: 'Bacardi',
        },
        uuid_client: '449203c6-7ed3-11e8-8b6b-0a35455287ac',
      },
    ],
    steps: [
      {
        css_coordinates: null,
        data_points: {},
        description: 'In autem esse dolore, qui dolore blandit et',
        display_order: 1,
        display_order_label: 'Decision 1',
        name: 'Lorum Ipsum',
        parent_id: null,
        steps: [
          {
            css_coordinates: null,
            data_points: {},
            description:
              'Luptatum ad blandit veniam eu consequaas asd as dsa asd asd asd asdsadsadsa sdfgdf gfd g fdgfdg fg fdg fdggg fds sfd gfdsg gs sdfg fg fdg ggs fgfsg',
            display_order: 1,
            display_order_label: 'Decision 1',
            name: 'Lorum Child 1',
            parent_id: 'b96574c5-85ea-40b6-b9b8-9335ed36f450',
            steps: [],
            uuid_step: 'aba89a2e-bbde-470e-950f-c31974f784b2',
          },
          {
            css_coordinates: null,
            data_points: {
              apps: [
                {
                  class: 'omni:app:audienceExplorer',
                  link: '/apps/audience_builder',
                  name: 'Audience Explorer',
                },
              ],
              shared_object: {
                input_label: 'Create New Persona',
                select_label: 'Select Existing Persona',
                title: 'Create persona',
                uuid_api_connection: null,
                uuid_category: '5c2869a9-1802-4526-bc19-94561f8279b7',
                uuid_sub_category: 'e7100292-1b39-4bd1-a855-fa1eaa4ebe4e',
              },
            },
            description: 'Luptatum ad blandit veniam eu consequa',
            display_order: 2,
            display_order_label: 'Decision 2',
            name: 'Lorum Child 2',
            parent_id: 'b96574c5-85ea-40b6-b9b8-9335ed36f450',
            steps: [],
            uuid_step: 'da9e78b2-7c45-4b34-8312-3c80bc0dea43',
          },
        ],
        uuid_step: 'b96574c5-85ea-40b6-b9b8-9335ed36f450',
      },
      {
        css_coordinates: null,
        data_points: {},
        description: 'Luptatum ad blandit veniam eu consequa',
        display_order: 2,
        display_order_label: 'Decision 2',
        name: 'Nulla autem',
        parent_id: null,
        steps: [
          {
            css_coordinates: null,
            data_points: {},
            description: 'Luptatum ad blandit veniam eu consequa',
            display_order: 1,
            display_order_label: 'Decision 1',
            name: 'Nulla Child 1',
            parent_id: 'de67b43a-88ba-4674-94e8-3e7cf854dcd2',
            steps: [],
            uuid_step: '782bd9a9-8bf6-45e3-a29c-899ecb6a381a',
          },
          {
            css_coordinates: null,
            data_points: {},
            description: 'Luptatum ad blandit veniam eu consequa',
            display_order: 2,
            display_order_label: 'Decision 2',
            name: 'Nulla Child 2',
            parent_id: 'de67b43a-88ba-4674-94e8-3e7cf854dcd2',
            steps: [],
            uuid_step: '0d80d651-d173-42a6-8598-80eb609d0575',
          },
        ],
        uuid_step: 'de67b43a-88ba-4674-94e8-3e7cf854dcd2',
      },
    ],
    uuid_framework_template: 'b9ac1a88-fea7-403f-906b-64310f7db866',
  },
  step_status: {
    '0d80d651-d173-42a6-8598-80eb609d0575': {
      completion: 100,
      icon: 'omni:informative:complete',
      id: 'complete',
      label: 'Complete',
    },
    '782bd9a9-8bf6-45e3-a29c-899ecb6a381a': {
      completion: 100,
      icon: 'omni:informative:complete',
      id: 'complete',
      label: 'Complete',
    },
    'aba89a2e-bbde-470e-950f-c31974f784b2': {},
    'b96574c5-85ea-40b6-b9b8-9335ed36f450': {},
    'da9e78b2-7c45-4b34-8312-3c80bc0dea43': {},
    'de67b43a-88ba-4674-94e8-3e7cf854dcd2': {},
  },
};
