import { api } from './helpers/api.js';
import WorkflowStepContainer from './workflow-step-container.js';

export default class WorkflowStep extends WorkflowStepContainer {
  constructor() {
    super();
    this.tabs = ['Modules', 'App Links', 'Guides'];
    this.stepLabel = 'Step';

    // If a child module gets renamed we need to refresh
    this.addEventListener('module-updated', this.reload);
  }

  static get properties() {
    return {
      data: { type: Object },
      stepId: this.routeParamProperty({ name: 'stepId' }),
      stageId: this.routeParamProperty({ name: 'stageId' }),
      workflowId: this.routeParamProperty({ name: 'workflowId' }),
    };
  }

  get breadcrumb() {
    return {
      label: this.data?.name,
      link: `workflow/${this.workflowId}/${this.stageId}/${this.stepId}`,
    };
  }

  loadData = () => api.getStep(this.stepId);
  loadDataProperty = 'stepId';
}

customElements.define('workflow-step', WorkflowStep);
