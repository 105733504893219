import {
  OmniElement,
  OmniStyleElement,
  OmniIconElement,
  html,
  nothing,
  css,
} from 'omni-ui';

import { Om2Table, Om2Dropdown } from 'omni-campaign-ui';
import { api } from './helpers/api.js';
import { showLoader } from './helpers/util.js';
import { OmniAppContainerMixin } from 'omni-app-container';
import { formatDate } from 'omni-campaign/src/helpers/utils.js';
import OmniAPI from 'omni-campaign/src/services/omni-api.js';

OmniStyleElement.register();
OmniIconElement.register();
Om2Table.register();
Om2Dropdown.register();

export default class HistoryTable extends OmniAppContainerMixin(OmniElement) {
  static get styles() {
    return [
      super.styles,
      css`
        .omni .filter {
          width: 22.125rem;
        }
        .omni .modal {
          position: absolute;
          top: 3rem;
          left: calc(100% - (1.875rem + 22.125rem));
        }
        .omni .modal.is-active {
          display: block;
        }
        .omni .modal-card-head {
          padding-top: 0.714rem;
          padding-bottom: 0;
        }
        .omni .modal-card-body {
          padding-top: 0.357rem;
          padding-bottom: 0.714rem;
        }
        .omni .modal-card-foot {
          padding-top: 0.714rem;
        }
        .clear-all-container {
          display: flex;
          justify-content: right;
        }
        .history-container {
          position: relative;
          min-height: 41.5rem;
        }
        .person-search {
          height: 2.571rem;
        }
      `,
    ];
  }

  /**
   * @property {Array} historyData - All history table entries
   * @property {Number} showMoreCount - Counter to track how many entries to display
   * @property {Number} pageNumber - Keeps track of the current page number for API.
   * @property {Number} itemsPerPage - The number of items to fetch per page.
   * @property {Boolean} isAllDataLoaded - A flag that indicates whether all history data has been fetched.
   *
   */
  static get properties() {
    return {
      env: this.contextProperty({ name: 'env' }),
      historyData: { type: Array },
      showMoreCount: { type: Number },
      pageNumber: { type: Number },
      itemsPerPage: { type: Number },
      isAllDataLoaded: { type: Boolean },
      filterModalActive: { type: Boolean },
      filterOptions: { type: Object },
      personFilter: { type: Array },
    };
  }

  constructor() {
    super();
    this.historyData = [];
    this.userInfo = {};
    this.pageNumber = 0;
    this.itemsPerPage = 100;
    this.isAllDataLoaded = false;

    this.filterModalActive = false;
    this.filterOptions = {};

    //omni-ui search only adds event listener if filter array.length > 0.
    this.personFilter = [0];
  }

  async firstUpdated() {
    this.loadData();
  }

  updated(changed) {
    if (changed.has('env') && this.env) {
      this.omniAPI = new OmniAPI(this.env);
    }
  }

  get breadcrumb() {
    return {
      label: 'History',
      link: 'history',
      toolbarActions: {},
    };
  }

  async loadData(reset) {
    if (reset) {
      this.pageNumber = 0;
      this.historyData = [];
      this.isAllDataLoaded = false;
    }

    this.isLoading = true;
    try {
      this.pageNumber += 1;

      const userResp = await showLoader(this, api.listHistoryUsers());

      await Promise.all(
        userResp.map(async user => {
          const personGuid = user.person_guid;
          const person = await (!this.userInfo[personGuid]
            ? this.omniAPI.getUser(personGuid)
            : Promise.resolve(this.userInfo[personGuid]));
          this.userInfo[personGuid] = person;
        })
      );
      this.personFilter = Object.values(this.userInfo).map(user => user.name);

      const resp = await showLoader(
        this,
        api.listHistoryItems({
          page: this.pageNumber,
          page_size: this.itemsPerPage,
          ...(Object.keys(this.filterOptions).length && {
            search: this.filterOptions,
          }),
        })
      );

      if (resp.page * resp.per_page >= resp.total) {
        this.isAllDataLoaded = true;
      }
      const entries = await Promise.all(
        resp.items.map(async item => {
          return {
            ...item,
            modified_date: formatDate(item.modified_date, true),

            //changing PascalCase to Proper Case
            object_type: item.object_type
              .replace(/([A-Z])/g, match => ` ${match}`)
              .replace(/^./, match => match.toUpperCase())
              .trim(),
            person_name: this.userInfo[item.person_guid]?.name,
          };
        })
      );

      this.historyData = [...(this.historyData ?? []), ...entries];
    } finally {
      this.isLoading = false;
    }
  }

  #showMoreData() {
    this.loadData();
  }

  #filterChange(e, type) {
    if (
      (typeof e.target.value === 'string' && e.target.value.trim() === '') ||
      e.target.value.length === 0
    ) {
      const nextFilterOptions = { ...this.filterOptions };
      delete nextFilterOptions[type];
      this.filterOptions = nextFilterOptions;
    } else {
      this.filterOptions = { ...this.filterOptions, [type]: e.target.value };
    }
  }

  /**
   *
   * @param {e} event from omni-search
   * @description method to filter dropdown search ahead of usernames
   */
  #personFilterChange(e) {
    this.personFilter = Object.values(this.userInfo).map(user => user.name);
    this.personFilter = this.personFilter.filter(person =>
      person.toLowerCase().includes(e.target.value.toLowerCase())
    );

    return this.personFilter;
  }

  /**
   *
   * @description method to apply all filters to history table on save and close modal.
   */
  #saveFilterChanges() {
    const keys = Object.keys(this.filterOptions);
    if (keys.length === 0) {
      this.filterModalActive = false;
      return;
    }
    const personNameFilter = this.filterOptions?.person_name;
    if (personNameFilter) {
      const values = Object.values(this.userInfo);
      const personGuidList = [];
      values.forEach(person => {
        if (
          person.name.toLowerCase().includes(personNameFilter.toLowerCase())
        ) {
          const personGuid = Object.keys(this.userInfo).find(
            key => this.userInfo[key] === person
          );
          personGuidList.push(personGuid);
        }
      });
      this.filterOptions = {
        ...this.filterOptions,
        personGuidList: personGuidList,
      };
    } else {
      const nextFilterOptions = { ...this.filterOptions };
      delete nextFilterOptions.personGuidList;
      this.filterOptions = nextFilterOptions;
    }

    this.loadData(true);
    this.filterModalActive = false;
  }

  #clearFilters() {
    this.filterOptions = {};
    this.pageNumber = 0;
    this.loadData(true);
  }

  get #filterTemplate() {
    const isFiltered = Object.keys(this.filterOptions).length ? true : false;

    const actionOptions = ['Create', 'Delete', 'Update'];

    const objectOptions = [
      { value: 'FrameworkTemplate', label: 'Framework Template' },
      { value: 'Module', label: 'Module' },
      { value: 'ModuleAssistField', label: 'Module Assist Field' },
      { value: 'ModuleTemplate', label: 'Module Template' },
    ];

    return html`
      <div class="modal ${this.filterModalActive ? 'is-active' : ''}">
        <div
          class="filter modal-card ${this.filterModalActive ? 'is-active' : ''}"
        >
          <header class="modal-card-head">
            <p class="modal-card-title">Filter</p>
            <button
              class="button is-text ml-4"
              title="Close"
              @click=${() => {
                this.filterModalActive = false;
              }}
            >
              <omni-icon
                class="is-size-2"
                icon-id="omni:interactive:close"
              ></omni-icon>
            </button>
          </header>
          <section class="modal-card-body">
            <div class="clear-all-container">
              <button
                class="button is-text is-small ${isFiltered
                  ? ''
                  : 'is-invisible'}"
                @click=${() => {
                  this.#clearFilters();
                }}
              >
                Clear All
              </button>
            </div>
            <div class="subtitle mb-2 mt-2">User</div>
            <omni-search
              class="person-search"
              isExpanded
              ph="Enter text to search"
              .filteredOptions="${this.personFilter}"
              @search-update=${e => {
                this.#personFilterChange(e);
                this.#filterChange(e, 'person_name');
              }}
              isOpen
              .value=${this.filterOptions['person_name']
                ? this.filterOptions['person_name']
                : ''}
            >
            </omni-search>
            <div class="subtitle mb-2 mt-2">Type</div>
            <om2-dropdown
              hasSearch
              isMulti
              .options="${objectOptions}"
              .value=${this.filterOptions['object_type']
                ? this.filterOptions['object_type']
                : ''}
              @change=${e => {
                this.#filterChange(e, 'object_type');
              }}
            ></om2-dropdown>
            <div class="subtitle mb-2 mt-2">ID</div>
            <input
              class="input input-card-input"
              type="search"
              @input=${e => {
                this.#filterChange(e, 'uuid_object');
              }}
              .value=${this.filterOptions['uuid_object']
                ? this.filterOptions['uuid_object']
                : ''}
            />
            <div class="subtitle mb-2 mt-2">Date</div>
            <input
              class="input input-card-input"
              type="date"
              @input=${e => {
                this.#filterChange(e, 'startDate');
              }}
              .value=${this.filterOptions['startDate']
                ? this.filterOptions['startDate']
                : ''}
            />
            <div class="subtitle mb-2 mt-2">to</div>
            <input
              class="input input-card-input"
              type="date"
              @input=${e => {
                this.#filterChange(e, 'endDate');
              }}
              .value=${this.filterOptions['endDate']
                ? this.filterOptions['endDate']
                : ''}
            />
            <div class="subtitle mb-2 mt-2">Action</div>
            <om2-dropdown
              hasSearch
              isMulti
              .options="${actionOptions}"
              .value=${this.filterOptions['action_type']
                ? this.filterOptions['action_type']
                : ''}
              @change=${e => {
                this.#filterChange(e, 'action_type');
              }}
            ></om2-dropdown>
          </section>
          <footer
            class="modal-card-foot is-flex is-justify-content-right is-align-items-center"
          >
            <div>
              <button
                @click=${() => {
                  this.#clearFilters();
                  this.filterModalActive = false;
                }}
                class="button has-text-primary is-text"
              >
                Cancel
              </button>
              <button
                type="submit"
                class="button is-primary "
                @click=${() => {
                  this.#saveFilterChanges();
                }}
              >
                Save
              </button>
            </div>
          </footer>
        </div>
      </div>
    `;
  }

  get #historyTemplate() {
    const showAll = this.isAllDataLoaded;
    return html` <omni-style>
      <div class="history-container">
        <om2-table
          shadowed
          autotooltip
          .columns=${[
            {
              label: 'user',
              key: 'person_name',
            },
            {
              label: 'type',
              key: 'object_type',
            },
            {
              label: 'id',
              key: 'uuid_object',
            },
            {
              label: 'action',
              key: 'action_type',
            },
            {
              label: 'timestamp',
              key: 'modified_date',
            },
          ]}
          .data="${this.historyData}"
          .keyFn=${({ uuid_object: id }) => id}
        >
          <span slot="header-start"></span>
          <span slot="header-end" class="is-flex">
            <button
              class="button is-text ml-4"
              title="Filter"
              @click=${() => {
                this.filterModalActive = true;
              }}
            >
              <omni-icon
                class="is-size-2"
                icon-id="omni:interactive:filter"
              ></omni-icon>
            </button>
          </span>
        </om2-table>
        <div class="p-5 has-text-centered">
          ${!showAll
            ? html`
                <button
                  @click=${() => {
                    this.#showMoreData();
                  }}
                >
                  Show more...
                </button>
              `
            : nothing}
        </div>
        ${this.#filterTemplate}
      </div>
    </omni-style>`;
  }

  render() {
    return html`<slot>${this.#historyTemplate}</slot>`;
  }
}

customElements.define('history-table', HistoryTable);
