import {
  OmniElement,
  OmniStyleElement,
  OmniIconElement,
  html,
  css,
} from 'omni-ui';
import 'omni-campaign/src/components/campaign-step-grid.js';
import 'omni-campaign/src/components/campaign-banner.js';
import 'omni-campaign/src/components/campaign-empathy-map.js';
import 'omni-campaign/src/components/campaign-donut-chart.js';
import {
  strategicDataSample,
  campaignDataSample,
} from './custom-themes-mock-data.js';

OmniStyleElement.register();
OmniIconElement.register();

/**
 * Class for Custom Themes Preview Element
 * Contains preview of theming for a variety of campaign components
 * @extends OmniElement
 */
export default class CustomThemesPreview extends OmniElement {
  /**
   * Custom Themes Preview Lit Properties
   * @property {Object} theme - Stores the theme that omni-ui > omni-theme uses to apply themes; key is variable name, value is RGB value
   */
  static get properties() {
    return { theme: { type: Object } };
  }

  /**
   * Custom Themes Preview styles
   */
  static get styles() {
    return [
      super.styles,
      css`
        omni-tile {
          margin-bottom: 2.143rem;
        }
        .title-wrapper {
          margin-left: 2.286rem;
        }
        omni-tile.module-wrapper::part(body-slot) {
          padding: 2.143rem 2.857rem 1.429rem 0;
        }
        .preview-elements {
          margin: 2.821rem 0.857rem 1.714rem 0.75rem;
          display: flex;
          flex-direction: column;
          gap: 5.714rem;
        }
        omni-toolbar .title1 {
          box-shadow: 0 0.21rem 0 0 var(--color-accent-one);
          padding-bottom: 0.36rem;
          min-width: 6.43rem;
        }
        omni-toolbar .title2 {
          box-shadow: 0 0.21rem 0 0 var(--color-accent-two);
          padding-bottom: 0.36rem;
          min-width: 6.43rem;
        }
        omni-toolbar .title3 {
          box-shadow: 0 0.21rem 0 0 var(--color-accent-three);
          padding-bottom: 0.36rem;
          min-width: 6.43rem;
        }
        omni-toolbar .title4 {
          box-shadow: 0 0.21rem 0 0 var(--color-accent-four);
          padding-bottom: 0.36rem;
          min-width: 6.43rem;
        }
        div.background-preview {
          background: var(--color-primary);
        }
        .stage-labels {
          display: flex;
          gap: 2.143rem;
        }
        .button-container {
          display: flex;
          gap: 0.714rem;
        }
        .form-fields {
          display: flex;
          gap: 5.686rem;
          justify-content: flex-start;
        }
        .form-fields div {
          flex-grow: 1;
        }
        .field-dropdown {
          max-width: 21.5rem;
          width: 100%;
        }
        .field-text-area {
          max-width: 32.857rem;
          width: 100%;
        }
        .visualizations {
          display: flex;
          gap: 5.686rem;
        }
        .donut-container {
          position: relative;
          flex-grow: 1;
          max-width: 400px;
          width: 100%;
        }
        .component-container {
          display: flex;
          flex-direction: column;
          gap: 1.429rem;
        }
        .upper-preview {
          display: flex;
          gap: 5.714rem;
        }
        .upper-preview-right {
          display: flex;
          flex-direction: column;
          gap: 4.286rem;
        }
        .upper-preview-right-outer {
          display: flex;
          gap: 5.714rem;
        }
        .upper-preview-right-outer-left {
          display: flex;
          flex-direction: column;
          gap: 4.286rem;
        }
        left-nav {
          display: flex;
          flex: 1 1 auto;
        }
        empathy-map {
          flex-grow: 1;
        }
        @media (max-width: 1105px) {
          .visualizations {
            flex-wrap: wrap;
          }
          .omni campaign-donut-chart svg {
            min-width: none !important;
          }
          .upper-preview-right-outer {
            flex-direction: column;
          }
          .stage-labels {
            flex-wrap: wrap;
          }
        }
        @media (max-width: 805px) {
          .upper-preview {
            flex-direction: column;
          }
        }
      `,
    ];
  }

  /**
   * @description Applies theme colors to preview elements on {@link #theme} property change
   * Calls {@link _applyStyle}
   * TODO investigate why setTimeout is needed to apply themes to step-card; seems to be an issue with render timing on step-card
   */
  updated() {
    setTimeout(this._applyStyle, 0);
  }

  /**
   * @description Recursively applies theme colors to preview elements with .themed class on {@link #theme} property update
   * @param nodes - Dom nodes that need styles; Defaulted to array of dom nodes with .themed class
   * @param entries - Array of arrays with key of color variable name and value of RGB color code
   */
  _applyStyle = (
    nodes = this.shadowRoot.querySelectorAll('.themed'),
    entries = Object.entries(
      Object.keys(this.theme ?? {}).length === 0
        ? this.defaultTheme
        : this.theme
    )
  ) => {
    // iterates through selected nodes looking for omni-style tags
    nodes.forEach(node => {
      let nextNodes = [...node.children];
      const omniStyle = node.shadowRoot?.querySelector('omni-style');
      // if omni-style exists on node, add its children to nextNodes
      if (omniStyle) {
        nextNodes = [...nextNodes, ...omniStyle.children];
        // uses omni-style.setProperty method to set each theme property
        entries.forEach(omniStyle.setProperty, omniStyle);
      }
      // recursively call _applyStyle on child nodes
      this._applyStyle(nextNodes, entries);
    });
  };

  /**
   * @returns {html} Rendered HTML for the component
   */
  render() {
    return html` <omni-style>
      <omni-tile class="module-wrapper themed">
        <div class="title-wrapper">
          <h1 class="title mb-0 is-2">Theme Preview</h1>
          <div class="preview-elements">
            <div class="upper-preview">
              <div class="component-container">
                <h1
                  class="title is-size-6 is-clamped has-text-grey-dark is-uppercase mb-0"
                >
                  Left Navigation
                </h1>
                <left-nav class="themed"></left-nav>
              </div>
              <div class="upper-preview-right">
                <div class="upper-preview-right-outer">
                  <div class="upper-preview-right-outer-left">
                    <div class="component-container">
                      <h1
                        class="title is-size-6 is-clamped has-text-grey-dark is-uppercase mb-0"
                      >
                        Tabs
                      </h1>
                      <div>
                        <button
                          class="button is-small is-shadowless is-primary is-active"
                        >
                          Workflow
                        </button>
                      </div>
                    </div>
                    <div class="component-container">
                      <h1
                        class="title is-size-6 is-clamped has-text-grey-dark is-uppercase mb-0"
                      >
                        Buttons
                      </h1>
                      <div class="button-container">
                        <button class="button is-primary">Primary</button>
                        <button class="button is-outlined">Secondary</button>
                      </div>
                    </div>
                  </div>
                  <div class="component-container">
                    <h1
                      class="title is-size-6 is-clamped has-text-grey-dark is-uppercase mb-0"
                    >
                      Step Card
                    </h1>
                    <step-card class="themed"></step-card>
                  </div>
                </div>
                <div class="component-container">
                  <h1
                    class="title is-size-6 is-clamped has-text-grey-dark is-uppercase mb-0"
                  >
                    Stages
                  </h1>
                  <omni-toolbar class="themed" slot="subheader">
                    <div class="stage-labels">
                      <h1 class="title1 is-3">Ambition</h1>
                      <h1 class="title2 is-3">Empathy</h1>
                      <h1 class="title3 is-3">Craft</h1>
                      <h1 class="title4 is-3">Activate</h1>
                    </div>
                  </omni-toolbar>
                </div>
              </div>
            </div>
            <campaign-banner
              .campaignSummary=${campaignDataSample}
              .readonly=${true}
              class="themed"
            ></campaign-banner>
            <div class="component-container">
              <h1
                class="title is-size-6 is-clamped has-text-grey-dark is-uppercase mb-0"
              >
                Form Fields
              </h1>
              <div class="form-fields">
                <div class="field-text-area">
                  <label class="subtitle mr-1 mb-2 text-area-label"
                    >Text area</label
                  >
                  <textarea
                    class="textarea"
                    placeholder="Text area text"
                  ></textarea>
                </div>
                <div class="mb-5 field-dropdown">
                  <label class="subtitle mr-1 mb-2 dropdown-label"
                    >Dropdown</label
                  >
                  <div>
                    <om2-dropdown
                      class="themed"
                      placeholder="Select Option"
                      .options=${['test1', 'test2']}
                      @change=${e => {
                        e.preventDefault();
                      }}
                    ></om2-dropdown>
                  </div>
                </div>
              </div>
            </div>
            <div class="component-container">
              <h1
                class="title is-size-6 is-clamped has-text-grey-dark is-uppercase mb-0"
              >
                Visualizations
              </h1>
              <div class="visualizations">
                <div class="donut-container">
                  <campaign-donut-chart
                    .strategicData=${strategicDataSample}
                    class="themed"
                  >
                  </campaign-donut-chart>
                </div>
                <empathy-map></empathy-map>
              </div>
            </div>
          </div>
        </div>
      </omni-tile>
    </omni-style>`;
  }
}

customElements.define('custom-themes-preview', CustomThemesPreview);
