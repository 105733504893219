import {
  OmniElement,
  OmniStyleElement,
  OmniIconElement,
  html,
  nothing,
} from 'omni-ui';
import { om2FormModal, om2alert, om2confirm, Om2Table } from 'omni-campaign-ui';
import { OmniAppContainerMixin } from 'omni-app-container';
import { showLoader } from './helpers/util.js';
import { api } from './helpers/api.js';

OmniStyleElement.register();
OmniIconElement.register();
Om2Table.register();

export default class ModuleAssistConfig extends OmniAppContainerMixin(
  OmniElement
) {
  static get properties() {
    return {
      moduleId: this.routeParamProperty({ name: 'moduleId' }),

      // Internal
      module: { type: Object },
      assistFields: { state: true },
    };
  }

  async updated(changed) {
    if (changed.has('moduleId') && this.moduleId) {
      this.module = await showLoader(this, api.getModule(this.moduleId));
      this.assistFields = this.module.assist_fields;
      this.dispatchNewEvent('breadcrumb-refresh');
    }
  }

  get breadcrumb() {
    return {
      label: `${this.module?.name ?? ''} - Assist Fields`,
      // link: No children routes so don't need a link for now
      toolbarActions: {
        Add: this.create,
      },
    };
  }

  async refresh() {
    this.assistFields = await api.listModuleAssistFields(
      this.module.uuid_module
    );
  }

  create = () => {
    // Get the list of assist fields for the current module that haven't been added
    const unused = (this.module.module_template.assist_fields || []).filter(
      fieldId => !this.assistFields.some(field => field.field_id === fieldId)
    );

    if (unused.length === 0) {
      om2alert('All assist fields currently added.', {
        type: 'warning',
        toast: true,
      });
      return;
    }

    om2FormModal({
      title: 'Add Omni Assist Enabled Field',
      data: {},
      onSubmit: async data => {
        await api.addModuleAssistField(
          this.module.uuid_module,
          data.field_id,
          data
        );
        await this.refresh();
      },
      onSuccess: () => {
        om2alert('Your prompt has been added', {
          type: 'success',
          toast: true,
        });
      },
      schema: {
        field_id: {
          title: 'Assist Field',
          required: true,
          type: 'string',
          enum: unused,
        },
        prompt: {
          title: 'Prompt',
          type: 'string',
          multiline: true,
          required: true,
          formModal: { fullWidth: true },
        },
      },
    });
  };

  delete(type) {
    om2confirm(
      `Permanently delete assist configuration for "${type.field_id}"?`,
      {
        callback: async () => {
          await api.deleteModuleAssistField(
            this.module.uuid_module,
            type.field_id
          );
          await this.refresh();
          om2alert('Your prompt has been deleted', {
            type: 'success',
            toast: true,
          });
        },
        type: 'warning',
      }
    );
  }

  edit(type) {
    om2FormModal({
      title: 'Edit Omni Assist Enabled Field',
      data: type,
      onSubmit: async data => {
        await api.editModuleAssistField(
          this.module.uuid_module,
          data.field_id,
          data
        );
        await this.refresh();
      },
      onSuccess: () => {
        om2alert('Your prompt has been updated', {
          type: 'success',
          toast: true,
        });
      },
      schema: {
        field_id: {
          title: 'Assist Field',
          type: 'string',
          disabled: true,
        },
        prompt: {
          title: 'Prompt',
          type: 'string',
          required: true,
          multiline: true,
          formModal: { fullWidth: true },
        },
      },
    });
  }

  render() {
    return html`
      <omni-style>
        <om2-table
          autosort
          autotooltip
          shadowed
          .search=${['field_id', 'prompt']}
          .columns=${[
            {
              label: 'assist field',
              key: 'field_id',
              isSortable: true,
              isMain: true,
            },
            {
              label: 'prompt',
              key: 'prompt',
              isSortable: true,
            },
            {
              label: 'actions',
              passthrough: true,
              template: assistConfig => html`
                <td>
                  <button
                    @click="${() => this.edit(assistConfig)}"
                    class="button is-text"
                    title="Edit"
                  >
                    <omni-icon
                      class="is-size-2"
                      icon-id="omni:interactive:edit"
                    ></omni-icon>
                  </button>
                  <button
                    @click=${() => this.delete(assistConfig)}
                    class="button is-text ml-4"
                    title="Delete"
                  >
                    <omni-icon
                      class="is-size-2"
                      icon-id="omni:interactive:delete"
                    ></omni-icon>
                  </button>
                </td>
              `,
            },
          ]}
          .data="${this.assistFields ?? []}"
        >
          <span slot="header-start">Omni Assist Enabled Fields</span>
          ${!this.assistFields?.length
            ? html`
                <div slot="body" class="p-5 has-text-centered has-text-shark">
                  <i>Click "Add" to customize a prompt for a field</i>
                </div>
              `
            : nothing}
        </om2-table>
      </omni-style>
    `;
  }
}

customElements.define('module-assist-config', ModuleAssistConfig);
