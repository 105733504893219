import {
  OmniElement,
  OmniStyleElement,
  OmniIconElement,
  html,
  css,
  nothing,
} from 'omni-ui';
import { OmniAppContainerMixin } from 'omni-app-container';
import { api } from './helpers/api.js';
import { showLoader, isSuperAdmin } from './helpers/util.js';
import './module-modal.js';
import './helpers/image-modal.js';
import { om2confirm, om2alert } from 'omni-campaign-ui';

OmniStyleElement.register();
OmniIconElement.register();

export default class ModuleList extends OmniAppContainerMixin(OmniElement) {
  static get properties() {
    return {
      data: { type: Array },
      tab: { type: Array },
      appTab: this.routeParamProperty({ name: 'appTab', source: 'tab' }),
      moduleId: this.routeParamProperty({ name: 'moduleId' }),
      stepId: this.routeParamProperty({ name: 'stepId' }),
      stageId: this.routeParamProperty({ name: 'stageId' }),
      workflowId: this.routeParamProperty({ name: 'workflowId' }),
      templateNames: { type: Array },
      modalInfo: { attribute: false },
    };
  }

  constructor() {
    super();
    this.data = [];
    this.tabs = ['All Modules', 'Dynamic', 'Standard'];
    [this.tab] = this.tabs;
  }

  connectedCallback() {
    super.connectedCallback();
  }

  updated(changedProperties) {
    // Detect when the router gives us router param updates. All router params should be
    // set together, so checking for "appTab" changes will trigger this when the route
    // is "/module" and changes to moduleId will also occur when the route is
    // "/workflow/:workflowId/:stageId/:stepId/module/:moduleId"
    // In both cases we reset state and load from backend.
    if (changedProperties.has('appTab') || changedProperties.has('moduleId')) {
      this.data = [];
      this.reload();
    }
  }

  /**
   * Manage Module styles
   */
  static get styles() {
    return [
      super.styles,
      css`
        ::part(module-type-img) {
          border-radius: 1rem;
        }
      `,
    ];
  }

  get breadcrumb() {
    const label = this.workflowId ? this.data?.[0]?.name : 'Module Library';
    const link = this.workflowId
      ? `workflow/${this.workflowId}/${this.stageId}/${this.stepId}/module/${this.moduleId}`
      : 'module';
    const toolbarActions = this.workflowId
      ? {}
      : { Add: () => this.addModule() };

    return { label, link, toolbarActions };
  }

  addModule() {
    this.openModulesModal('Add', null, false);
  }

  async editModule(moduleId) {
    const module = await showLoader(this, api.getModule(moduleId));
    this.openModulesModal('Edit', module, true);
  }

  async cloneModule(moduleId) {
    const module = await showLoader(this, api.getModule(moduleId));
    module.name = `${module.name} [cloned]`;
    delete module.uuid_module;
    this.openModulesModal('Clone', module, true);
  }

  async reload() {
    if (this.moduleId) {
      this.data = [await showLoader(this, api.getModule(this.moduleId))];
      this.dispatchNewEvent('breadcrumb-refresh');
    } else {
      // Only load the bare-minimum fields when we pull the full list
      this.data = await showLoader(
        this,
        api.listModules({
          fields: [
            'uuid_module',
            'name',
            'description',
            'module_type',
            'module_template',
          ],
        })
      );
    }
  }

  openModulesModal(title, module, locked) {
    this.modalInfo = { title, module, locked };
  }

  closeModulesModal() {
    this.modalInfo = undefined;
  }

  async openChildRoute(name, module) {
    let url = `module/${module.uuid_module}/${name}`;
    if (this.workflowId) {
      url = `workflow/${this.workflowId}/${this.stageId}/${this.stepId}/module/${module.uuid_module}/${name}`;
    }
    this.navigateTo(url);
  }

  openFilteredWorkflows(module) {
    const url = `module/${module.uuid_module}/workflows`;
    this.navigateTo(url);
  }

  renderTabs() {
    return this.tabs.map(
      tab => html`
        <button
          slot="header-start"
          class="button is-shadowless is-medium ${this.tab === tab
            ? 'is-primary is-active'
            : 'is-text is-alt-1'}"
          @click=${() => {
            this.tab = tab;
          }}
        >
          ${tab}
        </button>
      `
    );
  }

  deleteModule = async module => {
    try {
      await api.deleteModule(module.uuid_module);

      om2alert(`Module successfully deleted`, {
        type: 'success',
        toast: true,
        closeTimeout: 5000,
      });
      this.reload();
    } catch (e) {
      if (e.json.status === 409) {
        om2alert(
          `Module ${module.name} is currently in use on steps and cannot be deleted.`,
          { type: 'danger' }
        );
      } else {
        om2alert(`An error occurred: ${e.message}`, { type: 'danger' });
      }
    }
  };

  renderModules() {
    // Render the form builder instead of the table
    return html`
      ${this.modalInfo
        ? html` <module-modal
            .modalTitle=${this.modalInfo.title}
            .savedData=${this.modalInfo.module}
            .isModuleLocked=${this.modalInfo.locked}
            .closeModal=${() => this.closeModulesModal()}
            @module-updated=${() => this.reload()}
            @module-created=${e =>
              e.detail.module.module_type === 'form'
                ? this.openChildRoute('builder', e.detail.module)
                : this.reload()}
          ></module-modal>`
        : ''}

      <omni-style>
        <om2-table
          autosort
          autotooltip
          shadowed
          .search=${this.moduleId
            ? undefined
            : ['name', 'uuid_module', 'module_type']}
          .columns=${[
            {
              label: 'name',
              key: 'name',
              isSortable: true,
              isMain: true,
            },
            {
              label: 'id',
              key: 'uuid_module',
              isSortable: true,
            },
            {
              label: 'description',
              key: 'description',
              isSortable: true,
            },
            {
              label: 'type',
              key: 'module_type',
              isSortable: true,
              template: type =>
                html`<td class="is-clamped">
                  <span>
                    ${this.templateNames?.find(t => t.id === type)?.name ??
                    type}
                  </span>
                </td>`,
            },
            {
              label: 'Module Template Image',
              key: 'module_template',
              template: moduleTemplate => {
                if (!moduleTemplate?.image_url) return html`<td>n/a</td>`;
                return html`
                  <td
                    part="table-body-cell table-body-module-template"
                    class="has-text-centered is-clamped"
                  >
                    <image-modal
                      imageURL=${moduleTemplate?.image_url}
                    ></image-modal>
                  </td>
                `;
              },
              isSortable: false,
            },
            {
              label: 'actions',
              passthrough: true,
              template: module => html`
                <td>
                  <button
                    @click=${() => this.editModule(module.uuid_module)}
                    class="button is-text"
                    title="Edit"
                  >
                    <omni-icon
                      class="is-size-2"
                      icon-id="omni:interactive:edit"
                    ></omni-icon>
                  </button>
                  <button
                    @click="${() => this.cloneModule(module.uuid_module)}"
                    class="button is-text ml-4 ${this.moduleId
                      ? 'is-hidden'
                      : ''}"
                    title="Clone"
                  >
                    <omni-icon
                      class="is-size-2"
                      icon-id="omni:interactive:copy"
                    ></omni-icon>
                  </button>
                  ${module.module_type === 'form'
                    ? html`
                        <button
                          @click="${() => {
                            this.openChildRoute('builder', module);
                          }}"
                          class="ml-4 button is-text"
                          title="Form Builder"
                        >
                          <omni-icon
                            class="is-size-2"
                            icon-id="omni:interactive:build"
                          ></omni-icon>
                        </button>
                      `
                    : nothing}
                  ${module.module_template?.assist_fields?.length
                    ? html`<button
                        @click="${() => this.openChildRoute('assist', module)}"
                        class="button is-text ml-4"
                        title="Omni Assist"
                      >
                        <omni-icon
                          class="is-size-2"
                          icon-id="omni:brand:omni"
                        ></omni-icon>
                      </button>`
                    : nothing}
                  <button
                    @click="${() => this.openFilteredWorkflows(module)}"
                    class="button is-text ml-4"
                    title="Workflows"
                  >
                    <omni-icon
                      class="is-size-2"
                      icon-id="omni:object:workflow"
                    ></omni-icon>
                  </button>
                  ${isSuperAdmin()
                    ? html`
                        <button
                          @click="${async () => {
                            const isModuleInFramework =
                              await api.listFrameworkTemplates({
                                moduleId: module.uuid_module,
                                fields: ['uuid_framework_template'],
                              });
                            if (isModuleInFramework.length > 0) {
                              return om2confirm(
                                html`<p>
                                  This module, ${module.name}, is currently
                                  being used in a step and cannot be deleted.
                                  Refer to the workflows icon (
                                  <omni-icon
                                    class="is-size-2"
                                    icon-id="omni:object:workflow"
                                    style="vertical-align: middle; display: inline-block;"
                                  ></omni-icon>
                                  ) to see where it's being used.
                                </p>`,
                                {
                                  type: 'warning',
                                }
                              );
                            }
                            om2confirm(
                              'Are you sure you want to delete this module? This action is permanent and cannot be undone.',
                              {
                                callback: async () =>
                                  await this.deleteModule(module),
                                type: 'warning',
                              }
                            );
                          }}"
                          class="button is-text"
                          title="Delete"
                          ?disabled=${module.active}
                        >
                          <omni-icon
                            class="is-size-2"
                            icon-id="omni:interactive:delete"
                          ></omni-icon>
                        </button>
                      `
                    : nothing}
                </td>
              `,
            },
          ]}
          .data="${this.tab === this.tabs[0] // all modules
            ? this.data
            : this.data.filter(
                module =>
                  this.tab === this.tabs[1] // dynamic
                    ? module.module_type === 'form'
                    : module.module_type !== 'form' // standard
              )}"
        >
          ${this.moduleId ? nothing : this.renderTabs()}
        </om2-table>
      </omni-style>
    `;
  }

  render() {
    return html`<slot>${this.renderModules()}</slot>`;
  }
}

customElements.define('module-list', ModuleList);
